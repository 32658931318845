/*top menu*/
#top-info {
    background-color: #6c58ce;
    text-align: right;
    border-radius: 20px;
    color: white;
    font-size: 14px;
    border: 1px solid #eeeeee;
    width: 100%;
    padding: 20px 10px;
    margin: 10px 0;
    position: relative;
}

#top-info .user-info .user-info-title {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    font-weight: bold;
}

#top-info .user-info .user-info-title .user-info-title-name {
    font-family: 'Lato', sans-serif;
    padding-right: 5px;
}

#top-info .user-info .user-info-title .user-info-title-role {
    color: rgb(255, 246, 0);
    font-size: 16px;
}

#top-info .year-info {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

#top-info .year-info .icon {
    font-size: 25px !important;
    color: white;
    margin: 0 4px 0 14px;
}

#top-info .year-info span {
    font-family: 'Lato', sans-serif;
    padding-right: 5px
}

#top-info .date-info {
    flex: 2;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

#top-info .date-info .title {
    margin-left: 5px;
}

#top-info .date-info .icon {
    font-size: 25px !important;
    color: white;
    margin: 0 4px 0 14px;
}

#top-info .date-info span {
    font-family: 'Lato', sans-serif;
    padding-right: 5px
}

#top-info .info-notification {
    position: absolute;
    top: 15px;
    left: 15px;
}

#top-info .info-notification button {
    flex: 1;
    background-color: white;
    border: 8px;

    color: black;
    font-weight: bold;
    text-align: center;
    border-radius: 8px;
    float: left;

    align-items: center;
    position: relative;
    padding: 5px 5px 3px 5px;
    margin: 0;
    cursor: pointer;

}

#top-info .info-notification button .icon {
    font-weight: bold;
    font-size: 20px !important;
    color: black;
}

#top-info .info-notification button .notif {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
    top: -5px;
    right: 0;
    content: '.';
}

.main-header .menu-toggle {
    position: absolute;
    /*right: 10px;*/
    right: 0;
    top: 20px;
    display: none;
}

.menu-toggle i.icon {
    color: white !important;
    font-size: 24px !important;
}

@media (max-width: 992px) {
    #top-info {
        font-size: 15px;
        width: 100%;
        padding: 12px 35px;
        margin: 10px auto;
        position: relative;
    }

    #top-info .user-info .user-info-title {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        font-weight: bold;
    }

    #top-info .year-info {
        display: flex;
        flex-wrap: nowrap;
        padding: 0;
        flex-direction: row;
        margin: 10px 0 5px;
        justify-content: start;
        width: 100%;

    }

    #top-info .year-info .icon {
        font-size: 30px !important;
        color: white;
        margin: 0;
    }

    #top-info .year-info .title {
        margin-right:18px;
    }


    #top-info .date-info {
        margin: 10px 0 5px;
    }

    #top-info .date-info .title {
        margin-left: 5px;
    }

    #top-info .date-info .icon {
        font-size: 30px !important;
        color: white;
        margin: 0;
    }

    #top-info .date-info .title {
        margin-right: 18px;
    }

    #top-info .info-notification {
        flex: 1;
        text-align: center;
    }

    #top-info .info-notification button {

    }

    #top-info .info-notification button .icon {
        font-weight: bold;
        font-size: 20px !important;
        color: black;
    }

    #top-info .info-notification button .notif {
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: red;
        border-radius: 50%;
        top: -5px;
        right: 0;
        content: '.';
    }

    #top-info .info-notification {
        text-align: center;
        position: absolute;
    }

    .main-header .menu-toggle {
        display: block !important;
        z-index: 10;
    }

    #side-menu {
        position: relative;
    }

    #side-menu .menu-toggle {
        left: 10px !important;
        z-index: 100;
        position: absolute;
        display: inline !important;
        top: 20px;
    }


}


#side-menu .menu-toggle {
    display: none;
}



