.content-wrapper,.main-header {
    background-color: #f2f2f4;
}

.setting {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.setting .setting-card{
    background-color: white;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-items: center;
    align-items: center;
    justify-content: center;
    height: 120px;
    font-size: 14px;
    padding: 10px;
    border: 1px solid #eeeeee;
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 2px 10px 0 rgb(0 0 0 / 19%);
}

.setting .setting-card a {
    text-decoration: none;
}


.setting .setting-card:hover {
    background-color: #0a58ca;
    color: white;
}

.setting .setting-card:hover .icon {
    font-size: 50px !important;
    color: white !important;
}

.setting .setting-card .icon{
    font-size: 50px !important;
    color: black!important;
}
.setting .setting-card .setting-card-icon{
    padding: 10px;
}
.setting .setting-card .setting-card-title{
    font-size: 16px;
    color: black;
}

.setting .setting-card:hover .setting-card-title {
    font-size: 16px;
    color: white;
    font-weight: bold;
}
