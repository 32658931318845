.cheque-form{
    direction: rtl;
    text-align: right;
    background-color: white;
    border: 1px solid #eeeeee;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19)
}


.form-row{
    display: flex;
}

textarea,select{
    border-radius: 8px;
    background-color: white;
    border: none;
    line-height: 14px;
    width: 80%;
}


input.silver,textarea.silver,select.silver{
    background-color: #e8e8e8;
    border: 1px solid #e8e8e8;
}

.form-group .form-row{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
}
.form-group .row{
    padding: 10px 5px;
}

.cheque-form .card{
    width: 100%;
    margin: 2px;
    padding: 2px;
    border: none;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19)

}

.form{
    border-radius: 8px;
    border: 1px solid #eeeeee;
}

.card{
    margin: 10px 2px;
    border-radius: 8px;
    border: none;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
    height: 100%;
}

.card.active{
    border: 2px dashed #eeeeee;
}

.card .card-header{
    width: 100%;
    text-align: center;
    color: white;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: bold;
}

.card.pink{
    background-color: #ffe2c8;
}
.card.pink h5{
    color: #b53c00;
    font-weight: bold;
    margin-bottom: 10px;
}

.card.green{
    background-color: #01bf41;
}

.card.green label{
    color: white;
}

.card.red label{
    color: white;
}

.col-item{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.col-item label{
    display: block;
    min-width: 100px;
    font-size: 14px;
    font-weight: bold;
}

.form-control{
    border-radius: 8px !important;
    border: none !important;
}




select {

    /* styling */
    background-color: white;
    border: none;
    border-radius: 8px;
    display: inline-block;
    font: inherit;
    line-height: 1.2em;

    /* reset */

    margin: 0;
}

.col-footer{
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: relative;
}

.col-footer .buttons{
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.col-footer .delivery{
    position: absolute;
    left: 20px;
    width: 200px;
}

.form-check-label{
    padding-right: 20px;
}

.add-button{
    position: absolute;
    left: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
    font-size: 14px;
    background-color: #2d62ed;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 2px;
    border: none;
    border-radius: 8px;
}

.remove-button{
    position: absolute;
    left: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
    font-size: 14px;
    background-color: red;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 2px;
    border: none;
    border-radius: 8px;
}



