input[type=search]::-webkit-search-cancel-button {
    -webkit-appearance: searchfield-cancel-button;
}

tr.active {
    background-color: #d9edf7;
}

tr.active  td  span.icon {
    color: black !important;
    font-weight: bold !important;
}

@media only screen and (max-width: 900px) {
    table.dashboard-table tbody, thead {
        width: 100% !important;
    }

    table.dashboard-table tr {
       /* display: flex;
        flex-wrap: wrap;
        flex-direction: row;*/
        width: 100% !important;
        border-bottom: 2px solid #dee2e6;
    }

    table.dashboard-table tr td, table.dashboard-table tr th {
        border: none !important;
    }


    table.dashboard-table tr td:last-child span.icon {
        font-size: 24px !important;
    }



}
@media only screen and (max-width: 554px) {

table.dashboard-table tr td:last-child {
    width: 100%;

    justify-content: center;
}
}@media only screen and (max-width: 480px) {
    table.dashboard-table tr.active td:last-child {

    }
    table.dashboard-table tr {
         display: flex;
         flex-wrap: wrap;
         flex-direction: row;
        width: 100% !important;
        border-bottom: 2px solid #dee2e6;
    }
table.dashboard-table tr td:last-child {
    width: 100%;
    display: flex;
    justify-content: center;
}
}